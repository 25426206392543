<template>
	<div
		v-if="showWatermark"
		class="text-center text-white my-2">
		<p class="inline text-xs">Powered by</p>
		<img
			class="w-32 object-contain inline pl-2 pb-[1px]"
			alt="Powered By Mothership"
			src="/images/mothership_logo.webp" />
	</div>
</template>

<script setup>
	const runtimeConfig = useRuntimeConfig()
	const showWatermark = runtimeConfig.public.SHOW_MOTHERSHIP_WATERMARK
</script>
